import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid} from '@material-ui/core';
import Box from 'common/components/Box';
import BumperIcon from 'common/icons/bumper';
import BumperIcon1 from 'common/icons/bumper-1';
import BumperIcon2 from 'common/icons/bumper-2';

const styles = theme => ({
  layout: {
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    textAlign: 'center',
    fontSize: '1.53rem',
    fontWeight: '400',
    lineHeight: '32px',
    margin: '20px auto'
  },  
});

const bumperIcon = <BumperIcon />;
const bumperIcon1 = <BumperIcon1 />;
const bumperIcon2 = <BumperIcon2 />;

const items = [
  {
    svg: bumperIcon,
    title: 'Curated products for dropshipping',
    desc: 'At Dropseeker, we curate all of the products you’ll see featured. This means you won’t have to sift through a gigantic catalog of things that won’t work.'
  },
  {
    svg: bumperIcon1,
    title: 'Quickly test products on your store',
    desc: 'Our quick integration process means you can have one of our products in your e-commerce store in a matter of minutes. Start selling immediately.'
  },
  {
    svg: bumperIcon2,
    title: 'Integrate with ecommerce platforms',
    desc: 'We made it e-commerce platform agnostic. It can work with whatever you’re already using, fitting in seamlessly to your design without skipping a beat.'
  },
];

const Boxes = (props) => {
  const { classes } = props;

  return (
    <div className={classes.layout}>
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="center" >
          <Grid item xs={12}>
            <h3 className={classes.heading}>What can dropseeker do for you</h3>
          </Grid>
          {items && items.map((item, index) => 
            <Grid key={index} item xs={12} sm={12} md={4}>
              <Box key={index} item={item} />
            </Grid>)}
        </Grid>
      </Container>
    </div>
  );
};

Boxes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Boxes);