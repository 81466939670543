import React from 'react';
import { colors } from 'common/constants';


class InstagramIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    };
  }

  hover = () => {
    this.setState({
      hovered: !this.state.hovered
    });
  };

  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width="30"
        onMouseEnter={this.hover}
        onMouseLeave={this.hover}
      >
        <path
          fill={this.state.hovered ? `${colors.secondary}` : `${colors.black}`}
          d="M14.29,6.76a1.82,1.82,0,0,0-1-1,3,3,0,0,0-1-.19c-0.58,0-.75,0-2.22,0s-1.64,0-2.22,0a3,3,0,0,0-1,.19,1.82,1.82,0,0,0-1,1,3.05,3.05,0,0,0-.19,1c0,0.58,0,.75,0,2.22s0,1.64,0,2.22a3.05,3.05,0,0,0,.19,1,1.82,1.82,0,0,0,1,1,3,3,0,0,0,1,.19c0.58,0,.75,0,2.22,0s1.64,0,2.22,0a3,3,0,0,0,1-.19,1.82,1.82,0,0,0,1-1,3.05,3.05,0,0,0,.19-1c0-.58,0-0.75,0-2.22s0-1.64,0-2.22A3.05,3.05,0,0,0,14.29,6.76ZM10,12.82A2.82,2.82,0,1,1,12.82,10,2.82,2.82,0,0,1,10,12.82Zm2.94-5.1a0.66,0.66,0,1,1,.66-0.66A0.66,0.66,0,0,1,12.94,7.72ZM11.83,10A1.83,1.83,0,1,1,10,8.17,1.83,1.83,0,0,1,11.83,10ZM10,1a9,9,0,1,0,9,9A9,9,0,0,0,10,1Zm5.47,11.27a4,4,0,0,1-.26,1.34,2.81,2.81,0,0,1-1.61,1.61,4,4,0,0,1-1.34.26c-0.59,0-.77,0-2.27,0s-1.68,0-2.27,0a4,4,0,0,1-1.34-.26A2.81,2.81,0,0,1,4.79,13.6a4,4,0,0,1-.26-1.34c0-.59,0-0.77,0-2.27s0-1.68,0-2.27A4,4,0,0,1,4.79,6.4,2.81,2.81,0,0,1,6.4,4.79a4,4,0,0,1,1.34-.26c0.59,0,.77,0,2.27,0s1.68,0,2.27,0a4,4,0,0,1,1.34.26A2.81,2.81,0,0,1,15.21,6.4a4,4,0,0,1,.26,1.34c0,0.59,0,.77,0,2.27S15.49,11.68,15.47,12.27Z"
          pointerEvents="none"
        />
      </svg>
    );
  }
}

export default InstagramIcon;
