import React from 'react';
import { colors } from 'common/constants';


class FacebookIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    };
  }

  hover = () => {
    this.setState({
      hovered: !this.state.hovered
    });
  };

  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width="30"
        onMouseEnter={this.hover}
        onMouseLeave={this.hover}
      >
        <path
          fill={this.state.hovered ? `${colors.secondary}` : `${colors.black}`}
          d="M10,1a9,9,0,1,0,9,9A9,9,0,0,0,10,1Zm2.31,9.26H10.7V15.5H8.79V10.26H7.39V8.32h1.4V6.81A2.17,2.17,0,0,1,11.17,4.5h1.42V6.39H11.35a0.59,0.59,0,0,0-.65.53v1.4h1.91Z"
          pointerEvents="none"
        />
      </svg>
    );
  }
}

export default FacebookIcon;
