import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CategoryCard from 'common/components/CategoryCard';

import { ASSETS_ENDPOINT } from 'config';

const styles = (theme) => ({
  categoryLayout: {
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    flexGrow: 1,
    flexDirection: 'row',
    padding: theme.spacing(2, 0, 4, 0),
    background: theme.palette.background.paper,
  },
  categoryContent: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
    },
  },
  item: {
    flex: 1,
    padding: '1em',
  },
});

const CategoryList = (props) => {
  const { classes } = props;

  const categories = [
    {
      id: 'electronics',
      title: 'Electronics',
      thumbnailImageURL: ASSETS_ENDPOINT + '/category/laptop-color.svg',
    },
    {
      id: 'home',
      title: 'Home',
      thumbnailImageURL: ASSETS_ENDPOINT + '/category/hoover.svg',
    },
    {
      id: 'fashion',
      title: 'Fashion',
      thumbnailImageURL: ASSETS_ENDPOINT + '/category/handbag.svg',
    },
    {
      id: 'beauty',
      title: 'Beauty',
      thumbnailImageURL: ASSETS_ENDPOINT + '/category/perfume.svg',
    },
    {
      id: 'household',
      title: 'Household',
      thumbnailImageURL: ASSETS_ENDPOINT + '/category/chair.svg',
    },
  ];

  return (
    <div className={classes.categoryLayout}>
      <Container maxWidth="lg">
        <div className={classes.categoryContent}>
          {categories.map((category) => (
            <Link
              legacyBehavior
              href={{
                pathname: '/search',
                query: {
                  type: 'product',
                  'category.main': category.id,
                },
              }}
              key={category.id}
            >
              <div className={classes.item}>
                <LazyLoadComponent>
                  <CategoryCard category={category} />
                </LazyLoadComponent>
              </div>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(CategoryList);

CategoryList.propTypes = {
  classes: PropTypes.object.isRequired,
};
