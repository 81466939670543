import React from 'react';

export default function BumperIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42">
      <g fill="none" fillRule="evenodd">
        <path fill="#553030" d="m36 5-21.0630512 37-13.9369488-16.0126959z" />
        <path fill="#a75edd" d="m35 0-21.0630512 38-13.9369488-16.0570483z" />
        <path stroke="#fff" strokeLinecap="square" d="m3.09289674 22.1954834 10.12478286 11.8045166 16.8752171-29.79182072" />
      </g>
    </svg>
  );
}