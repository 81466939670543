import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    margin: '10px 10px',
    padding: '20px',
    border: '1px solid rgb(224, 224, 224)',
    borderRadius: '8px',
    background: 'transparent',
    boxShadow: 'none'
  },
  content: {
    padding: '0px !important',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: '0.990rem',
    fontWeight: '500',
    lineHeight: '24px',
    margin: '10px 0px 8px 0',
    color: 'black'
  },
  desc: {
    color: 'rgba(0,0,0,.54)',
    marginBottom: '0',
    fontSize: '.910rem',
    fontWeight: '400',
    lineHeight: '20px'
  },
  footer: {
    justifyContent: 'center',
    margin: '10px 0 20px 0',
  },
  btnSelect: {
    fontSize: 16,
    letterSpacing: '0.9px',
    border: '2px solid #142158',
    '&:hover': {
      background: 'white',
      border: '2px solid #142158',
      color: '#142158',
    },
  },
});

const Box = (props) => {
  const classes = useStyles();
  const { item: { svg, title, desc} } = props;
  
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {svg}
        <Typography className={classes.title} color="textSecondary">
          {title}
        </Typography>
       
        <Typography variant="body2" component="p" className={classes.desc}>
          {desc}
        </Typography>
      </CardContent>
    
    </Card>
  );
};

Box.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Box;
