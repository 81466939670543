import React from 'react';

export default function BumperIcon2() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="42" viewBox="0 0 36 42">
      <g fill="none" fillRule="evenodd">
        <path fill="#9D2A41" d="m20 3h14v32h-14z" />
        <path fill="#e13d5e" d="m22 0h14v32h-14z" />
        <path stroke="#fff" d="m25 1c.0640947 5.73532876.9063746 11.033691 2.5268399 15.8950868 1.6204652 4.8613957 3.7781853 8.5630335 6.4731601 11.1049132h-9" />
        <path fill="#9D2A41" d="m2 7h14v32h-14z" />
        <path fill="#e13d5e" d="m0 10h14v32h-14z" />
      </g>
    </svg>
  );
}