import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectOneIfNotProvided } from 'common/helpers/select';
import CollectionList from './CollectionList';

const mapStateToProps = createStructuredSelector({
  collection: selectOneIfNotProvided(
    'collections',
    'collection',
    'collectionID'
  )
});

export default connect(mapStateToProps, null)(CollectionList);
