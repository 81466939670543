import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { colors } from 'common/constants';
import FacebookIcon from './icons/fb';
import TwitterIcon from './icons/twitter';
import InstagramIcon from './icons/instagram';
import { useSelector } from 'react-redux';
import { selectVersionNumber } from '../../store/selector/application';

const styles = (theme) => ({
  footerWrapper: {
    backgroundColor: `${colors.footer}`,
  },
  root: {
    flex: 'none',
    flexShrink: '0',
    marginTop: 30,
    overflow: 'hidden',
    color: `${colors.white}`,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      padding: '8px 16px 0 16px',
      maxWidth: '1368px',
      margin: '0 auto',
    },
  },
  topFooter: {
    borderBottom: `1px solid ${colors.border}`,
    padding: '1.5rem 1rem 1.5rem 0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  footer: {
    padding: '8px 16px 8px 16px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  copyRight: {
    padding: '16px 16px 16px 16px',
    textAlign: 'center',
  },
  copyRightText: {
    color: `${colors.black}`,
    fontSize: '12px',
    letterSpacing: '0.68px',
    lineHeight: '14px',
  },
  title: {
    fontWeight: 700,
    color: `${colors.black}`,
    fontSize: '16px',
    letterSpacing: '1.12px',
    lineHeight: '19px',
    marginBottom: '15px',
  },
  description: {
    color: `${colors.black}`,
    fontSize: '15px',
    letterSpacing: 1,
    lineHeight: '19px',
  },
  footerActionContainer: {
    padding: '1.5rem 1rem 1.5rem 0.5rem',
    [theme.breakpoints.up('sm')]: {
      borderBottom: '1px solid rgb(215, 215, 215)',
    },
  },
  actionItem: {
    fontWeight: 700,
    color: `${colors.black}`,
    padding: '0.5rem 1.5rem',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      padding: '0.25rem 0.75rem',
      fontWeight: 'bold',
    },
  },
  borderedItem: {
    borderRight: '1px solid rgb(215, 215, 215)',
    borderLeft: '1px solid rgb(215, 215, 215)',
  },
  phone: {
    padding: '0px 1.5rem 0px 1.5rem',
    fontWeight: 400,
    color: `${colors.black}`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      padding: '0px 0.75rem 0px 1rem',
    },
  },
  info: {
    color: `${colors.black}`,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    margin: '0px 10px 10px 0px',
    cursor: 'pointer',
  },
  footerActions: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      order: 2,
    },
  },
});

function Footer(props) {
  const { classes } = props;
  const versionNumber = useSelector(selectVersionNumber);

  const infoList = [
    {
      text: 'About Us',
      args: {
        href: '/about',
      },
    },
    {
      text: 'Terms and Privacy',
      args: {
        href: '/terms',
      },
    },
    {
      text: 'Blog',
      args: {
        href: '/blog',
      },
    },
  ];

  return (
    <div className={classes.footerWrapper}>
      <div className={classes.root}>
        <Grid
          container
          spacing={0}
          className={classes.footerActionContainer}
          alignItems="center"
        >
          <Grid item xs={12} sm={9} md={9} className={classes.footerActions}>
            <Typography
              display="inline"
              variant={'subtitle1'}
              className={classes.phone}
            >
              <a href={'tel:01860515136'}>01860515136</a>
              {/* 01860515136 */}
            </Typography>

            <a href="mailto:help@dropseeker.com">
              <Typography
                display="inline"
                variant={'subtitle1'}
                className={classNames(classes.actionItem, classes.borderedItem)}
              >
                help@dropseeker.com
              </Typography>
            </a>

            <Link href="/faq" legacyBehavior>
              <Typography
                display="inline"
                variant={'subtitle1'}
                className={classes.actionItem}
              >
                FAQ
              </Typography>
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={0}
          className={classes.footerActionContainer}
          justifyContent="center"
        >
          <Hidden only={['xs', 'sm']}>
            <Grid className={classes.footer} item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.title}
                style={{ color: `${colors.secondary}` }}
              >
                Dropseeker
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.description}
              >
                Curated, dropshipped products sourced for you to easily import
                into your eCommerce store and delight your customers - in only a
                few clicks.
              </Typography>
            </Grid>
          </Hidden>

          <Hidden only={['xs', 'sm']}>
            <Grid className={classes.footer} item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                gutterBottom
                className={classes.title}
              >
                Company
              </Typography>

              {infoList.map((item, index) => (
                <Link {...item.args} legacyBehavior key={index}>
                  <Typography variant="subtitle1" className={classes.info}>
                    {item.text}
                  </Typography>
                </Link>
              ))}
            </Grid>
          </Hidden>

          <Grid className={classes.footer} item xs={12} sm={4}>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.title}
            >
              Follow Us
            </Typography>

            <Typography component="div" gutterBottom>
              <Typography
                variant="subtitle1"
                display="inline"
                className={classes.icon}
              >
                <FacebookIcon />
              </Typography>

              <Typography
                variant="subtitle1"
                display="inline"
                className={classes.icon}
              >
                <TwitterIcon />
              </Typography>

              <Typography
                variant="subtitle1"
                display="inline"
                className={classes.icon}
              >
                <InstagramIcon />
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid className={classes.copyRight} item xs={12}>
            <Typography className={classes.copyRightText} variant={'subtitle1'}>
              Copyright © {new Date().getFullYear()} Dropseeker v{versionNumber}
              , LLC. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
