import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import SearchBarHomePanel from 'common/components/Panels/SearchBarHome';
import { colors } from 'common/constants';
import { ASSETS_ENDPOINT } from '../../config';
import axios from 'axios';

const LottieDynamic = dynamic(() => import('lottie-react'), {
  loading: () => <p>Loading...</p>,
});

const styles = (theme) => ({
  heroLayout: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0, 1),
    },
  },
  heroContent: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap-reverse',
    },
  },
  heroTitle: {
    color: `${colors.bannerText}`,
    fontSize: '44px',
    fontWeight: 'bold',
    letterSpacing: '1.12px',
    lineHeight: '50px',
    fontFamily: 'Helvetica',
    margin: theme.spacing(13, 0, 2, 0),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(20, 0, 0),
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: theme.spacing(4, 0, 0),
      fontSize: '24px !important',
      lineHeight: '28px',
    },
  },
  subTitle: {
    fontSize: '20px !important',
    lineHeight: '27px !important',
    fontWeight: '300',
    margin: theme.spacing(1, 0, 2),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: theme.spacing(1, 0, 0),
      fontSize: '16px !important',
      lineHeight: '24px !important',
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    color: '#FFFFFF',
    letterSpacing: 1,
    fontSize: '16px !important',
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  secondaryButton: {
    margin: theme.spacing(2, 0, 2, 0),
    justifyContent: 'center',
  },
  heroImage: {
    width: '100%',
    maxWidth: '560px',
    minWidth: '340px',
    verticalAlign: 'middle',
    [theme.breakpoints.down('xs')]: {
      height: '95%',
      marginTop: theme.spacing(4),
    },
  },
});

const Banner = (props) => {
  const { classes } = props;

  const [animationData, setAnimationData] = useState(undefined);

  useEffect(() => {
    axios
      .get(`${ASSETS_ENDPOINT}/dropseeker-animation-home.json`)
      .then((response) => {
        setAnimationData(response.data);
      });
  }, []);

  return (
    <div className={classes.heroLayout}>
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography
              component="h1"
              variant="h2"
              className={classes.heroTitle}
            >
              Discover products <br />
              your customers will love
            </Typography>
            <Typography component="p" className={classes.subTitle}>
              Dropseeker is a product aggregator that helps online business
              owners discover products to sell on their store.
            </Typography>
            <SearchBarHomePanel />
          </Grid>
          <Grid item xs={12} sm={6}>
            {!!animationData && (
              <LottieDynamic
                className={classes.heroImage}
                animationData={animationData}
                loop
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(styles)(Banner);

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};
