import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  card: {
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 4px 0px',
    display: 'flex',
    flexDirection: 'column',
    height: '230px',
    flexGrow: 0,
    flexShrink: 1,
    '-webkit-box-flex': 0,
    '-webkit-box-pack': 'justify',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '22px',
    overflow: 'hidden',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: theme.spacing(1),
    transition: 'box-shadow .5s ease-in-out',
    borderStyle: 'solid',
    border: '1px solid #E0E0E0',
    '&:hover': {
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
    },
    [theme.breakpoints.down('sm')]: {
      height: '210px',
      width: '180px',
    },
  },
  cardMedia: {
    display: 'flex',
    alignSelf: 'flex-end',
    paddingTop: '0.5em',
    marginRight: '-25%!important',
    marginBottom: '-12px !important',
  },
  cardContent: {},
  titleCategory: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
      lineHeight: '18px !important',
    },
  },
});

class CategoryCard extends React.Component {
  static defaultProps = {
    category: {},
  };

  static propTypes = {
    category: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes, category } = this.props;

    return (
      <Fragment>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography component="h2" className={classes.titleCategory}>
              {category.title}
            </Typography>
          </CardContent>

          <CardMedia
            component="img"
            className={classes.cardMedia}
            src={category.thumbnailImageURL}
          />
        </Card>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CategoryCard);
