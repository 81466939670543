import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EmailSignupPanel from 'common/components/Panels/EmailSignup';
import { colors } from 'common/constants';

const styles = (theme) => ({
  emailSignupLayout: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  previewContainer: {
    margin: '10px 0',
    textAlign: 'center',
    '& p': {
      marginTop: '0',
      fontSize: '1.1rem ',
      color: 'rgb(51, 51, 51)',
    },
  },
  heading: {
    color: `${colors.bannerText}`,
    fontSize: '48px',
    fontWeight: '700',
    letterSpacing: '-0.8px',
    lineHeight: '56px',
    marginBottom: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '34px !important',
      lineHeight: '40px',
      marginBottom: '0.5rem',
    },
  },
  subHeading: {
    fontSize: '16px',
    lineHeight: '24px',
    color: `${colors.black}`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '0.5rem',
    },
  },
  signupBtn: {
    height: '60px',
    lineHeight: '60px',
    borderRadius: 0,
    width: '100%',
    fontWeight: 800,
    fontSize: '1.1rem',
    letterSpacing: '0.9px',
    padding: 0,
  },
  info: {},
});

const EmailSignup = (props) => {
  const { classes } = props;
  return (
    <div className={classes.emailSignupLayout}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.previewContainer}
      >
        <Grid item xs={12}>
          <Typography component="h2" className={classes.heading}>
            Let’s keep in touch
          </Typography>

          <Typography component="subtitle1" className={classes.subHeading}>
            Get recommendations, tips, updates, promotions and more.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <EmailSignupPanel />
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(EmailSignup);

EmailSignup.propTypes = {
  classes: PropTypes.object.isRequired,
};
