import React from 'react';
import { colors } from 'common/constants';


class TwitterIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false
    };
  }

  hover = () => {
    this.setState({
      hovered: !this.state.hovered
    });
  };

  render() {
    return (
      <svg
        viewBox="0 0 20 20"
        width="30"
        onMouseEnter={this.hover}
        onMouseLeave={this.hover}
      >
        <path
          fill={this.state.hovered ? `${colors.secondary}` : `${colors.black}`}
          d="M10,1a9,9,0,1,0,9,9A9,9,0,0,0,10,1Zm4.57,6.5V7.57h0C14.73,11.68,11.78,15,8,15a6.71,6.71,0,0,1-3.69-1.12,3.7,3.7,0,0,0,3.27-1.05A2.27,2.27,0,0,1,5.44,11.1a1.72,1.72,0,0,0,1-.08A2.43,2.43,0,0,1,4.61,8.54a1.43,1.43,0,0,0,1,.29,2.66,2.66,0,0,1-.72-3.35,6.63,6.63,0,0,0,5,2.63h0a2.69,2.69,0,0,1-.06-0.57A2.45,2.45,0,0,1,12.21,5a2.27,2.27,0,0,1,1.7.78h0a3.65,3.65,0,0,0,1.47-.56,2.68,2.68,0,0,1-1,1.38,3.92,3.92,0,0,0,1.31-.4A4.25,4.25,0,0,1,14.57,7.5Z"
          pointerEvents="none"
        />
      </svg>
    );
  }
}

export default TwitterIcon;
