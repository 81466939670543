import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GOOGLE_LOGIN_CLIENT_ID } from 'config';

// Redux
import {
  displaySearchBar,
  getCollectionList,
  loginGoogle,
} from 'common/store/actions';
import { getCurrentUser } from 'common/helpers/select';

// Components
import Page from 'common/components/Page';
import Footer from 'common/components/Footer';
import Banner from './Banner';
import Boxes from './Boxes';
import CategoryList from './CategoryList';
import CollectionList from './CollectionList';
import EmailSignup from './EmailSignup';

const HomePage = (props) => {
  const { collectionIDs } = props;

  const handleOneTapSuccess = (res) => {
    const { credential } = res;

    return props.loginGoogle({
      tokenId: credential,
    });
  };

  useEffect(() => {
    const oneTap = () => {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_LOGIN_CLIENT_ID,
        callback: handleOneTapSuccess,
      });
      window.google.accounts.id.prompt();
    };

    if (document.readyState === 'complete') {
      oneTap();
    } else {
      window.onload = oneTap;
    }
  }, []);

  useEffect(() => {
    props.displaySearchBar(false);

    return function cleanup() {
      props.displaySearchBar(true);
    };
  }, [props]);

  return (
    <Page>
      <Banner user={props.user} />
      <CategoryList user={props.user} />
      {collectionIDs.slice(1, 3).map((collectionID) => (
        <CollectionList key={collectionID} collectionID={collectionID} />
      ))}
      <Boxes />
      {collectionIDs.slice(3, 4).map((collectionID) => (
        <CollectionList key={collectionID} collectionID={collectionID} />
      ))}
      <EmailSignup />
      <Footer />
    </Page>
  );
};

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  categoryLoading: state.app.categoryLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    displaySearchBar: bindActionCreators(displaySearchBar, dispatch),
    getCollectionList: bindActionCreators(getCollectionList, dispatch),
    loginGoogle: bindActionCreators(loginGoogle, dispatch),
  };
};

HomePage.defaultProps = {
  collectionIDs: [],
  displaySearchBar: () => null,
  loginGoogle: () => null,
  user: {},
};

HomePage.propTypes = {
  collectionIDs: PropTypes.array,
  displaySearchBar: PropTypes.func,
  loginGoogle: PropTypes.func,
  user: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
