import { compose, keys, pathOr, pipe, propEq, reject } from 'ramda';

// Redux
import { wrapper } from 'common/store';
import { displaySearchBar, getCollectionList } from 'common/store/actions';

// Components
import HomePage from 'modules/Home';
import withLoadUser from 'common/components/withLoadUser';

export const getServerSideProps = wrapper.getServerSideProps(
  compose(withLoadUser)((store) => async () => {
    store.dispatch(displaySearchBar(false));
    const ret = await store.dispatch(getCollectionList());

    return {
      props: {
        collectionIDs: pipe(
          pathOr({}, ['entities', 'collections']),
          reject(propEq('featured-collection', 'code')),
          keys,
        )(ret),
      },
    };
  }),
);

export default HomePage;
