import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Link from 'next/link';
import ProductCard from 'common/components/ProductCard/ProductCard';
import { Container, Grid, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Slider from 'react-slick';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = (theme) => ({
  collectionLayout: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 0, 4),
  },
  desktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  productsList: {
    postion: 'relative',
    display: 'flex',
    overflowX: 'auto',
    padding: '20px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      padding: '10px 0',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  collectionContent: {
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  item: {
    minWidth: 280,
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: 600,
    fontSize: '26px !important',
    lineHeight: '32px !important',
    letterSpacing: '1.48px !important',
    color: '#1B2936 !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px !important',
      lineHeight: '26px !important',
    },
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px !important',
    lineHeight: '18.0px !important',
    letterSpacing: '0.8px !important',
    color: '#7C7C7C !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '16px !important',
    },
  },
  seeMore: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px !important',
    fontWeight: 900,
    lineHeight: '16.0px !important',
    letterSpacing: '0.8px !important',
    color: theme.palette.secondary.dark,
    textDecoration: 'uppercase',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      lineHeight: '15px !important',
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  seeMoreIcon: {
    display: 'none',
    fontSize: '12px !important',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  arrowIconGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  arrowIcon: {
    margin: '5px',
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '32px',
    height: '32px',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

    '&:hover': {
      transform: 'scale(1.04, 1.04)',
      color: theme.palette.secondary.dark,
      padding: '2px',
    },
  },
  showMoreLink: {
    [theme.breakpoints.up('sm')]: {
      marginRight: '10px',
      textDecoration: 'underline',
    },
  },
});

const settings = {
  dots: false,
  arrows: false,
  lazyLoad: true,
  infinite: false,
  speed: 200,
  slidesToShow: 4,
  slidesToScroll: 4,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const CollectionList = (props) => {
  const { classes, collection } = props;

  const slider = useRef(null);

  const onNext = () => {
    slider.current.slickNext();
  };

  const onPrevious = () => {
    slider.current.slickPrev();
  };

  return (
    <div className={classes.collectionLayout}>
      <Container maxWidth="lg">
        <div className={classes.collectionContent}>
          <Typography component="h2" variant="h2" className={classes.title}>
            {collection.title}
          </Typography>

          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Grid item xs={8}>
                <div className={classes.description}>
                  <Typography component="h4">
                    {collection.description}
                  </Typography>
                </div>
              </Grid>
              <Grid container item xs={4} justifyContent="flex-end">
                <div className={classes.arrowIconGroup}>
                  <Link href="/search" legacyBehavior>
                    <div className={classes.showMoreLink}>
                      <Typography component="h4" className={classes.seeMore}>
                        SEE MORE{' '}
                        <ArrowForwardIosIcon className={classes.seeMoreIcon} />
                      </Typography>
                    </div>
                  </Link>
                  <ChevronLeftIcon
                    className={classes.arrowIcon}
                    onClick={onPrevious}
                  />
                  <ChevronRightIcon
                    className={classes.arrowIcon}
                    onClick={onNext}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Slider ref={slider} {...settings} className={classes.desktop}>
            {propOr([], 'entries', collection).map((item) => (
              <Link href={`/products/${item.id}`} key={item.id} legacyBehavior>
                <div className={classes.item}>
                  <LazyLoadComponent>
                    <ProductCard productID={item.id} />
                  </LazyLoadComponent>
                </div>
              </Link>
            ))}
          </Slider>

          <div className={classes.productsList}>
            {propOr([], 'entries', collection).map((item) => (
              <Link href={`/products/${item.id}`} key={item.id} legacyBehavior>
                <div className={classes.item}>
                  <LazyLoadComponent>
                    <ProductCard productID={item.id} />
                  </LazyLoadComponent>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

CollectionList.defaultProps = {
  collection: {},
};

CollectionList.propTypes = {
  classes: PropTypes.object.isRequired,
  collection: PropTypes.object,
};

export default withStyles(styles)(CollectionList);
